import "./styles.scss"
import React from "react"
import Grid from "@material-ui/core/Grid"
import SubmitForm from "./submitForm"
import PhoneNumbersView from "../phoneNumbersView"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"
import axios from "axios"
import CircularProgress from "@material-ui/core/CircularProgress"
import makeStyles from "@material-ui/core/styles/makeStyles"
import AppContext from "./../appContext"
import { PAGES_ID } from "./../../constants"

const useStyles = makeStyles(() => ({
  loader: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1500,
    opacity: 0.5,
    backgroundColor: "white",
  },
  loaderContainer: {
    position: "relative",
  },
  root: {
    margin: 8,
  },
}))

const ContactForm = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const classes = useStyles()
  const appDispatcher = React.useContext(AppContext)

  const handleSubmit = React.useCallback(
    contactData => {
      setIsLoading(true)
      appDispatcher({ type: "loader", isLoading: true })
      return axios
        .post("/api/contact-form/submit", contactData)
        .then(() => {
          window.ym && window.ym(62270920, "reachGoal", "car-cost")
          window.gtag &&
            window.gtag("event", "conversion", {
              sent_to: "AW-640190028/yacuCJCx6M8BEMyMorEC",
            })
          appDispatcher({
            type: "open",
            severity: "success",
            message:
              "Спасибо за вашу заявку! Мы вам перезвоним в ближайшее время.",
          })
        })
        .catch(err => {
          const action = {
            type: "open",
            severity: "error",
          }
          switch (err?.response?.status) {
            case 400: {
              action.message = err.response.data
              break
            }
            case 413: {
              action.message =
                "Слишком много фотографий, уменьшите их количество или добавьте фото более низкого качества."
              break
            }
            default: {
              action.message =
                "Сервер не доступен, пожалуйста, повоторите попытку позже."
            }
          }

          appDispatcher(action)
          throw err
        })
        .finally(() => {
          setIsLoading(false)
          appDispatcher({ type: "loader", isLoading: false })
        })
    },
    [setIsLoading, appDispatcher]
  )

  return (
    <div className={classes.root}>
      <div id={PAGES_ID.CONTACTS} className="contact-point"></div>
      <div className="contacts-title">
        <p className="contacts-title-text smooth-borders">Контакты</p>
      </div>
      <Grid container justify="center">
        <Grid container style={{ maxWidth: "800px" }}>
          <Grid item xs={12} style={{ minHeight: 200 }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ height: "100%", width: "100%" }}
              className="contact-description"
              spacing={3}
            >
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Hidden mdUp>
                    <Typography gutterBottom>
                      Заполните контактные данные или позвоните нам.
                    </Typography>
                  </Hidden>
                  <Hidden smDown>
                    <Typography gutterBottom>
                      Заполните контактные данные или позвоните нам по номерам:
                    </Typography>
                  </Hidden>
                  <Hidden smDown>
                    <PhoneNumbersView isMessengerVisible />
                  </Hidden>
                  <Typography gutterBottom style={{ width: "100%" }}>
                    Пожалуйста, добавьте фото автомобиля.
                  </Typography>
                  <Typography gutterBottom>
                    В поле дополнительно укажите дополнительные параметры и
                    дефекты.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className={isLoading ? classes.loaderContainer : ""}
            item
            xs={12}
          >
            {isLoading && (
              <div className={classes.loader}>
                <CircularProgress></CircularProgress>
              </div>
            )}
            <SubmitForm onSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

ContactForm.displayName = "ContactForm"
export default ContactForm
