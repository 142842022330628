import React from "react"

import "../styles/main.scss"
import logo from "../images/logo1.svg"

import Layout from "../components/layout"
import PhoneNumbersView from "../components/phoneNumbersView"
import ContactForm from "../components/contactForm"
import AdvantagesView from "../components/advantagesView"
import NoSsr from "@material-ui/core/NoSsr"
import Grid from "@material-ui/core/Grid"
import ColorButton from "./../components/colorButton"

import { PAGES_ID } from "../constants"

import { withStyles } from "@material-ui/core/styles"

const LearnMoreButtonView = withStyles(theme => ({
  root: {
    lineHeight: 2.5,
    border: "5px solid",
    borderColor: "#2F4858",
    borderRadius: "15px",
    fontWeight: "bold",
  },
}))(ColorButton)

const chemOfWork = [
  {
    title: "Оформление заявки",
    text: "Звоните по телефону прямо сейчас",
    icon: "fa-phone-alt",
    hasPhone: true,
  },
  {
    title: "Бесплатная оценка",
    text: "Наш квалифицированный специалист бесплатно оценит ваш автомобиль",
    icon: "fa-search",
    hasPhone: false,
  },
  {
    title: "Получите деньги",
    text: "Вы получаете договор и деньги в день обращения и без посредников",
    icon: "fa-file-invoice-dollar",
    hasPhone: false,
  },
]

const shakeAnimationClassName = "shake-animation"

const IndexPage = () => {
  const [isAnimationEnabled, setIsAnimationEnabled] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      const showAnimation = () => {
        setIsAnimationEnabled(true)
        setTimeout(hideAnimation, 1000)
      }
      const hideAnimation = () => {
        setIsAnimationEnabled(false)
        setTimeout(showAnimation, 6000)
      }

      showAnimation()
    }, 1500)
  }, [])

  return (
    <Layout>
      <div className="new-scroll wrapper">
        <div className="nav--anchor-add"></div>
        <div className="main-img">
          <div className="text-container">
            <div className="main-text">
              <h1 className="text smooth-borders">
                Быстрый выкуп авто в Беларуси
              </h1>
            </div>
            <div className="main__second-text second-text">
              <span className="text2">
                Срочный выкуп авто на выгодных условиях. Оформляем продажи за 15
                минут. Выкуп легковых автомобилей быстро, дорого.
              </span>
              <br />
              <PhoneNumbersView classForPhones={["text2", "text2"]} />
            </div>
            <div className="main__second-text main__learn-more">
              <LearnMoreButtonView
                fullWidth
                size="large"
                href={`#${PAGES_ID.CONTACTS}`}
                className={isAnimationEnabled ? shakeAnimationClassName : ""}
              >
                Узнать стоимость
              </LearnMoreButtonView>
            </div>
          </div>
        </div>
        <div className="nav">
          <a className="nav__logo" href="#">
            <img alt="логотип" src={logo} className="logo-img" />
          </a>
          <nav role="navigation">
            <div className="nav-menu">
              <a href={`#${PAGES_ID.ADVANTAGES}`}>
                <div className="nav-menu-item">Преимущества</div>
              </a>
              <a href={`#${PAGES_ID.CHEMA}`}>
                <div className="nav-menu-item">Схема работы</div>
              </a>
              <a href={`#${PAGES_ID.CONTACTS}`}>
                <div className="nav-menu-item">Контакты</div>
              </a>
            </div>
          </nav>
          <div className="nav-contact">
            <PhoneNumbersView isMessengerVisible={true} />
          </div>
        </div>
        <AdvantagesView />
        <div id={PAGES_ID.CHEMA} className="chema-point"></div>
        <div className="shema">
          <div className="shema-title">
            <div className="shema-title-text smooth-borders">Схема работы</div>
          </div>
          <Grid component="ul" container className="shema-row items-background">
            {chemOfWork.map(({ title, text, icon, hasPhone }) => (
              <Grid
                component="li"
                key={title}
                xs={6}
                className="shema-card smooth-borders"
              >
                <div className="shema-card-top">
                  <i className={`fas ${icon} fa-5x`}></i>
                </div>
                <div className="shema-card-bottom">
                  <span className="bold">{title}</span>
                  <br />
                  {text}
                  {hasPhone && (
                    <>
                      <br />
                      <Grid container justify="center">
                        <Grid item justify="center">
                          <PhoneNumbersView />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
        <NoSsr>
          <ContactForm />
        </NoSsr>
      </div>
    </Layout>
  )
}
export default IndexPage
