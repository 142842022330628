import React from "react"
import Grid from "@material-ui/core/Grid"
import { PAGES_ID } from "./../../constants"

const advantagesView = () => {
  return (
    <>
      <div id={`${PAGES_ID.ADVANTAGES}`} className="advantages-point"></div>
      <div className="preimushestvo">
        <div className="preimushestvo-title">
          <div className="preimushestvo-title-text smooth-borders">
            Наши преимущества
          </div>
        </div>
        <Grid
          className="items-background"
          container
          component="ul"
          justify="space-around"
        >
          <Grid
            className="preimushestvo-card-item"
            xs={6}
            md={3}
            item
            justify="center"
            component="li"
          >
            <div className="preimushestvo-card">
              <div className="preimushestvo-card-top">
                <i className="far fa-clock fa-5x"></i>
                <span className="bold">Быстро</span>
              </div>
              <span className="preimushestvo-card-body second-text">
                Выкупим Ваше авто в короткие сроки
              </span>
            </div>
          </Grid>
          <Grid
            className="preimushestvo-card-item"
            xs={6}
            md={3}
            item
            justify="center"
            component="li"
          >
            <div className="preimushestvo-card">
              <div className="preimushestvo-card-top">
                <i className="fas fa-car fa-5x"></i>
                <span className="bold">Бесплатный выезд</span>
              </div>
              <span className="preimushestvo-card-body second-text">
                Наш специалист приедит в любую точку Беларуси
              </span>
            </div>
          </Grid>
          <Grid
            className="preimushestvo-card-item"
            xs={6}
            md={3}
            item
            justify="center"
            component="li"
          >
            <div className="preimushestvo-card">
              <div className="preimushestvo-card-top">
                <i className="fas fa-handshake fa-5x"></i>
                <span className="bold">Помощь в оформлении</span>
              </div>
              <span className="preimushestvo-card-body second-text">
                Поможем оформить все необходимые документы в ГАИ за наш счет
              </span>
            </div>
          </Grid>
          <Grid
            className="preimushestvo-card-item"
            xs={6}
            md={3}
            item
            justify="center"
            component="li"
          >
            <div className="preimushestvo-card">
              <div className="preimushestvo-card-top">
                <i className="fas fa-check-square fa-5x"></i>
                <span className="bold">Большой опыт</span>
              </div>
              <span className="preimushestvo-card-body second-text">
                Вам поможет команда специалистов с большим опытом
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

advantagesView.displayName = "AdvantagesView"
export default advantagesView
