import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Checkbox from "@material-ui/core/Checkbox"
import { colorMain } from "./const/color"

const ColorCheckbox = withStyles({
  root: {
    color: colorMain,
    "&$checked": {
      color: colorMain,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

ColorCheckbox.displayName = "ColorCheckbox"

export default ColorCheckbox
